import React, { useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Button, Dropdown, Modal, Form } from "react-bootstrap";
import { UseAPI } from "../../../services/UseAPI";
import Swal from "sweetalert2";

const KegiatanRekap = () => {
    const history = useHistory();
    const [topTenData, setTopTenData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showModal, setShowModal] = useState(false);
    const [putaranForm, setPutaranForm] = useState({
      tanggal_awal: '',
      tanggal_akhir: '',
      jumlah_dipilih: '',
      jumlah_kandidat: '',

    });

    const router = useRouteMatch();
    React.useEffect( async() => {
      const response =  await UseAPI(`admin/kegiatan/rekap/${router.params.id}`,{},'GET');
      setTopTenData(response);
      console.log(response);
      setLoading(false)
    }, []);

    const putaran = async () => {
      setShowModal(true);
    }

    const handlePutaranFormChange = (event) => {
      setPutaranForm({
        ...putaranForm,
        [event.target.id]: event.target.value
      });
    }

    const submitPutaranForm = async () => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // putaranForm.id_kegiatan = router.params.id
          const response = await UseAPI(`admin/kegiatan/putaran/${router.params.id}`, putaranForm, 'POST');
          console.log(response);
          if(response.result === 'success') {
            setShowModal(false);
            Swal.fire(
              response.result,
              response.message,
              response.icon
            );
            // Optionally, refresh the data or navigate to a new page
          } else {
            console.error('Failed to create new putaran');
            Swal.fire(
              response.result,
              response.message,
              response.icon
            );
          }
        }
      });
      // const response = await UseAPI(`admin/kegiatan/rekap/${router.params.id}/putaran`, putaranForm, 'POST');
      // if(response.success) {
      //   setShowModal(false);
      //   // Optionally, refresh the data or navigate to a new page
      // } else {
      //   console.error('Failed to create new putaran');
      // }
    }
  
  return (
    <>
      <div>
        <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
          <button onClick={()=>history.goBack()} className="btn btn-secondary mb-3">
            <i className="fas fa-arrow-left"></i> Kembali
          </button>
        </div>
        <h5>Rekap Kegiatan</h5>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="card mt-4">
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <span>{topTenData && topTenData.length} Kandidat Teratas</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Jumlah Suara</span>
              </div>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {topTenData.map((data, index) => (
                  <li key={index} className={`d-flex justify-content-between p-2 ${index % 2 === 0 ? 'bg-light' : 'bg-white'}`}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      {data.nama_peserta}
                    </div>
                    <div>
                        {data.total}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="card-footer">
              <Button variant="primary" onClick={() => history.push(`/admin/kegiatan/rekap/${router.params.id}/belum_vote`)}>Belum Vote</Button>
              <Button variant="primary" onClick={putaran}>Buat Putaran Berikutnya</Button>
            </div>
          </div>
        </>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Putaran Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Tanggal Mulai</Form.Label>
              <Form.Control type="datetime-local" id="tanggal_awal" value={putaranForm.tanggal_awal} onChange={handlePutaranFormChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tanggal Berakhir</Form.Label>
              <Form.Control type="datetime-local" id="tanggal_akhir" value={putaranForm.tanggal_akhir} onChange={handlePutaranFormChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Jumlah Kandidat</Form.Label>
              <Form.Control type="number" id="jumlah_kandidat" value={putaranForm.jumlah_kandidat} onChange={handlePutaranFormChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Jumlah Dipilih</Form.Label>
              <Form.Control type="number" id="jumlah_dipilih" value={putaranForm.jumlah_dipilih} onChange={handlePutaranFormChange} />
            </Form.Group>
            <Button variant="primary" onClick={submitPutaranForm}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default KegiatanRekap;