import React from "react"
import { useHistory } from "react-router-dom"
import { UseAPI } from "../../../services/UseAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const KegiatanTambah = () => {
    const history = useHistory();
    const [formData, setFormData] = React.useState({
        nama_kegiatan: '',
        tanggal_awal: '',
        tanggal_akhir: '',
        aktif: '1',
        putaran: '1'
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const simpanKegiatan = async (e) => {
        e.preventDefault();
        console.log(formData);

        if (!formData.nama_kegiatan || !formData.tanggal_awal || !formData.tanggal_akhir) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill in all required fields!',
            });
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Saving...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    const response = await UseAPI('admin/kegiatan', formData, 'POST');
                    console.log(response); // Handle response as needed
                    Swal.close();
                    Swal.fire(
                        'Saved!',
                        'Your activity has been saved.',
                        'success'
                    );
                } catch (error) {
                    console.error(error); // Handle error as needed
                    Swal.fire(
                        'Error!',
                        'There was an error saving your activity.',
                        'error'
                    );
                }
            }
        });
    };

    return (
        <>
            <div>
                <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
                    <button onClick={() => history.push('/admin')} className="btn btn-secondary mb-3">
                        <i className="fas fa-arrow-left"></i> Kembali
                    </button>
                </div>
                <h5>Tambah Kegiatan</h5>
            </div>

            <div className="card">
                <div className="card-body">
                    <form onSubmit={simpanKegiatan}>
                        <div className="form-group pb-3">
                            <label htmlFor="nama_kegiatan">Nama Kegiatan</label>
                            <input type="text" className="form-control" id="nama_kegiatan" placeholder="Enter Nama Kegiatan" value={formData.nama_kegiatan} onChange={handleChange} />
                        </div>
                        <div className="form-group pb-3">
                            <label htmlFor="tanggal_awal">Tanggal Awal</label>
                            <input type="date" className="form-control" id="tanggal_awal" value={formData.tanggal_awal} onChange={handleChange} />
                        </div>
                        <div className="form-group pb-3">
                            <label htmlFor="tanggal_akhir">Tanggal Akhir</label>
                            <input type="date" className="form-control" id="tanggal_akhir" value={formData.tanggal_akhir} onChange={handleChange} />
                        </div>
                        <div className="form-group pb-3">
                            <label htmlFor="aktif">Aktif</label>
                            <select className="form-control" id="aktif" value={formData.aktif} onChange={handleChange}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="form-group pb-3">
                            <label htmlFor="putaran">Putaran</label>
                            <select className="form-control" id="putaran" value={formData.putaran} onChange={handleChange}>
                                <option value="1">Satu</option>
                                <option value="2">Dua</option>
                                <option value="3">Tiga</option>
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary ">Simpan</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default KegiatanTambah;
