import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Button, Dropdown } from "react-bootstrap";
import { UseAPI } from "../../../services/UseAPI";

const KegiatanRekapBelumVote = () => {
    const history = useHistory();
    const [topTenData, setTopTenData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    const router = useRouteMatch();
    React.useEffect( async() => {
      const response =  await UseAPI(`admin/kegiatan/rekap/${router.params.id}/belum_vote`,{},'GET');
      setTopTenData(response);
      setLoading(false)
    }, []);
  
  return (

    <>
      <div>
        <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
          <button onClick={() => history.goBack()} className="btn btn-secondary mb-3">
            <i className="fas fa-arrow-left"></i> Kembali
          </button>
        </div>
        <h5>Peserta Belum Vote</h5>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="card mt-4">
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {topTenData[0].map((data, index) => (
                  <li key={index} className={`d-flex justify-content-between p-2 ${index % 2 === 0 ? 'bg-light' : 'bg-white'}`}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      {(index+1)}. {data.nama_peserta}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="card-footer">
              <Button variant="primary" onClick={() => history.push(`/admin/kegiatan/rekap/${router.params.id}/belum_vote`)}>Belum Vote</Button>
            </div>
          </div>
        </>
      )}
    </>


  )
}
export default KegiatanRekapBelumVote;