import axios from 'axios';
import swal from "sweetalert";
import { UseAPI } from './UseAPI';
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(data) {
    // return UseAPI('daftar-peserta',{nama_peserta:'test'},'POST');
    // return data;
    // axios call
    const formData = new FormData();
    formData.append('kode_peserta',data.kode_peserta)
    formData.append('nama_peserta',data.nama_peserta)
    formData.append('no_telp',data.no_telp)
    formData.append('gender',data.gender)
    formData.append('nama_kegiatan','milad61')
    // const postData = formData;
    return axios.post(
        `https://apps.unismuh.ac.id/event_api/daftar-peserta`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },            
        }
    );
}

export function login(email, password) {
    return UseAPI('login-member',{kode_peserta:email,password:password},'POST');
}

export function formatError(errorResponse) {
    // console.log(errorResponse);
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
            break;
        default:
            swal("Gagal", errorResponse.error.data, "error", {
                button: "Coba kembali!",
            }).then((value) => {
                window.location.reload();
                // Event handling after swal button is clicked
            });
            break;
            // return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

// ini yang buat auto logout kalau session habis
export function runLogoutTimer(dispatch, timer, history) {
    return history
    // setTimeout(() => {
    //     dispatch(logout(history));
    // }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}
