import React from "react"
import { NavLink, useHistory } from "react-router-dom"
import { UseAPI } from "../../services/UseAPI";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AdminPage = () => {
  const history = useHistory();
  const handleBack = () => {
    history.push('/');
  }

  // console.log("props",props);

  const user = JSON.parse(localStorage.getItem('userDetails'));
  if (user.role !== 'admin') {
    history.push('/dashboard');
  }
  // console.log(user);

  const [topTenData, setTopTenData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect( async() => {
    const response =  await UseAPI(`admin/kegiatan`,{},'GET');
    setTopTenData(response); 
    setLoading(false)
  }, []);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
          <button onClick={handleBack} className="btn btn-secondary mb-3">
            <i className="fas fa-arrow-left"></i> Kembali
          </button>
          <NavLink className={'btn btn-secondary mb-3'} to={'/admin/kegiatan/tambah'} ><FontAwesomeIcon icon={faPlus} /> Vote</NavLink>
        </div>
        <h5>Daftar Vote</h5>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="card mt-4">
          <div className="card-header">
            <div className="flex flex-justify-between">
              Nama Vote
            </div>
            <div className="flex flex-justify-between">
              Aksi
            </div>
          </div>
        <div className="card-body">
          <ul className="list-group list-group-flush">
            {topTenData.map((data, index) => (
              <li key={index} className="d-flex justify-content-between py-2">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  {data.nama_kegiatan}
                </div>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id={`dropdown-basic-${index}`}>
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => history.push(`/admin/kegiatan/rekap/${data.id_kegiatan}`)}>Rekap</Dropdown.Item>
                      <Dropdown.Item onClick={() => history.push(`/admin/kegiatan/peserta/${data.id_kegiatan}`)}>Tambah Peserta</Dropdown.Item>
                      <Dropdown.Item onClick={() => history.push(`/admin/kegiatan/edit/${data.id_kegiatan}`)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => history.push(`/admin/kegiatan/delete/${data.id_kegiatan}`)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            ))}
          </ul>
        </div>
        </div>
      )}
    </>
  )
}

export default AdminPage
