import { Editor } from '@tinymce/tinymce-react';
import React from 'react'
import { useEffect,useState } from 'react';
import Select from "react-select";
// const getData = (element) => {
//     return  (element.datasource && element.datasource.masterdata && (
//         element.datasource.masterdata.map((item,key)=>{
//            setOptionData(current => [...current,"<option value=1>1</option>"])
//        })
//     ))

// }
const options = (props) => {
    const optiondata = [];
    props.datasource && props.datasource.masterdata.map((item)=>{
        optiondata.push({value:item.id,label:item.type_name})    
    })

    return optiondata
};



const FormElements = ( props ) => {    
    const [optionData,setOptionData] = useState([])
    useEffect(()=>{
        props.datasource && props.datasource.masterdata.map((item)=>{
            // setOptionData(current => [...current,item])
            setOptionData(values => ({...values, [item]: item}))

            
        })
        //console.log(optionData);
    },[])

  switch (props.type) {
    case 'select':
        return (
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">{props.label}</label>
                <div className="col-sm-9">
                {/* <Select 
                    name={props.name}
                    onChange={props.onChange}
                    options={options(props)}
                    // className="form-control"

                />  */}
                <select
                    name={props.name}
                    onChange={props.onChange}
                    className="form-control"
                >
                    <option key={'0'} value={'0'}>Pilih Tipe</option>
                    {
                        options(props).map((item,key)=> {
                            if (parseInt(item.value) === parseInt(props.value)) {
                                return <option key={key} value={item.value} selected={'selected'}>{item.label}</option>
                            } else {
                                return <option key={key} value={item.value} >{item.label}</option>
                            }
                        })
                    }
                </select>
                </div>
            </div>    
        )        
        break;
    case 'textarea':
        return (
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">{props.label}</label>
                <div className="col-sm-9">
                <Editor
                    id={props.name}
                    name={props.name}
                    initialValue={`${props.value}`}
                    init={{
                        height: 200,
                        menubar: false,                    
                        plugins: [
                            "advlist autolink lists link image code charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                            "bullist numlist outdent indent | removeformat | help ",
                            content_style: 'body { color: #828282 }'
                        ,branding: false
                    }}
                    onChange={props.onChange}
                />                    
                </div>
            </div>    
        )
        break;
    default:
        return (
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">{props.label}</label>
                <div className="col-sm-9">
                <input
                    name={props.name}
                    type={props.type}
                    className="form-control"
                    placeholder={props.label}
                    onChange={props.onChange}
                    defaultValue={props.value}
                />
                </div>
            </div>    
        )
        break;
  }
}

export default FormElements
