import React, { Component, useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";

const PageTitle = ({ motherMenu, activeMenu, pageContent }) => {
  const [finalPath, setFinalPath] = useState(null);
  useEffect(()=>{
	let path = window.location.pathname.split("/");
	let ujungPath = path[path.length - 1];
	//console.log(isNaN(ujungPath));
	switch (path.length) {
	  case 3:
		  setFinalPath(path[path.length - 1])
		  break;	
	  case 4:
		  setFinalPath(path[path.length - 2])
		  break;	
	  case 5:
		  setFinalPath(path[path.length - 3])
		  break;
	  case 6:
		  if (isNaN(ujungPath)) {
			setFinalPath(path[path.length - 4]+'/'+path[path.length - 3])
 		  } else {
			setFinalPath(path[path.length - 4]+'/'+path[path.length - 3]+'/'+ujungPath)
		  }
		  break;
	  case 7:
		  setFinalPath(path[path.length - 5])
		  break;
	}
	// //console.log("finalPath",finalPath);
	//console.log("path",path);
  },[])
//   export default breadcrumb = () => {
// 	let path = window.location.pathname.split("/");
// 	//console.log(path);	
// 	return (
// 			<ol className="breadcrumb">
// 				<li className="breadcrumb-item active"><Link to={`/${finalPath}`}>{motherMenu}</Link></li>
// 				<li className="breadcrumb-item  ">{activeMenu}</li>
// 			</ol>
// 	);
//   }
  return (
    <div className="row page-titles mx-0">
		{ /* <div className="col-sm-6 p-md-0">
			<div className="welcome-text">
			  <h4>Hi, welcome back!</h4>
			  <p className="mb-0">
				{pageContent ? pageContent : "Your business dashboard template"}
			  </p>
			</div>
		  </div>
		  <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
			<ol className="breadcrumb">
			  <li className="breadcrumb-item">
				<Link to={`/${path[path.length - 1]}`}>{motherMenu}</Link>
			  </li>
			  <li className="breadcrumb-item active c">
				<Link to={`/${path[path.length - 1]}`}>{activeMenu}</Link>
			  </li>
			</ol>
		  </div> */}
			<ol className="breadcrumb">
				<li className="breadcrumb-item active"><Link to={`/${finalPath}`}>{motherMenu}</Link></li>
				<li className="breadcrumb-item  ">{activeMenu}</li>
			</ol>
    </div>
  );
};

export default PageTitle;
