import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSailboat, faSquare, faNewspaper, faUser } from '@fortawesome/free-solid-svg-icons'

const NavHader = () => {
  return (
    <div className="nav-header d-flex justify-content-center p-3">
        <Link to="/" className="btn btn-sm btn-primary">
            <FontAwesomeIcon icon={faUser} />
        </Link>
    </div>
  );
};

export default NavHader;
