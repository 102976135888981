import React, { useContext } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./step.css";

import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import Home from "./components/Dashboard/Home";
import ChartJs from "./components/charts/Chartjs";
import { ThemeContext } from "../context/ThemeContext";
import TryoutDetail from "./components/Tryout/TryoutDetail";
import Tryout from "./components/Tryout/Tryout";
import TryoutStart from "./components/Tryout/TryoutStart";
import Vote from "./pages/Vote";
import UserProfile from "./pages/UserProfile";
import AdminPage from "./pages/AdminPage";
import KegiatanRekap from "./components/administrator/KegiatanRekap";
import KegiatanTambah from "./components/administrator/KegiatanTambah";
import KegiatanPeserta from "./components/administrator/KegiatanPeserta";
import KegiatanPesertaTambah from "./components/administrator/KegiatanPesertaTambah";
import KegiatanRekapBelumVote from "./components/administrator/KegiatanRekapBelumVote";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    ///tryout
    { url: "vote/:id", component: Vote },

    ///profile
    { url: "profile", component: UserProfile },

    // admin page
    {url : "admin",component: AdminPage},
    {url : "admin/kegiatan/rekap/:id",component: KegiatanRekap},
    {url : "admin/kegiatan/rekap/:id/belum_vote",component: KegiatanRekapBelumVote},
    {url : "admin/kegiatan/tambah",component: KegiatanTambah},
    {url : "admin/kegiatan/peserta/:id",component: KegiatanPeserta},
    {url : "admin/kegiatan/peserta/tambah/:id",component: KegiatanPesertaTambah}



  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  //console.log(path);

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            // style={{ marginTop:0 }}
            style={{ minHeight: window.screen.height - 300 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>      
    </>
  );
};

export default Markup;
