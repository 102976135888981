import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import DonutChart from './Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import banner from "../../../images/back2.png";

const Home = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const user = JSON.parse(localStorage.getItem('userDetails'));
	//console.log(user);
	useEffect(() => {
		UseAPI(`peserta/${user.kode_peserta}`, {}, 'GET')
		.then((resp) => {
				console.log("resp", resp);
				setData(resp.data);
				setLoading(false);
			})
	}, []);

	return (
		<>
			<div style={{marginBottom: '90px'}}>
								<div className="card">
									<div className="card-header p-3 m-0 text-center">
										<h5 className='mb-0'>Data Pemilih</h5>
									</div>
									<div className="card-body">
										<div className='flex d-flex justify-content-between pb-2'>
											<div className='flex-start'>Nama</div>
											<div className='flex-end'>{user.nama_peserta}</div>
										</div>
										<div className='flex d-flex justify-content-between pb-2'>
											<div className='flex flex-start'>Kode Pemilih</div>
											<div className='flex flex-end'>{user.kode_peserta}</div>
										</div>
									</div>
								</div>

				<img className='rounded border border-1 border-dark' src={banner} alt="User" style={{ width: '100%', height: '100%' }} />
				<div className="card" style={{ margin: '0 auto', marginTop: '10px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
					<div className="card-body">

						{loading ? (
							<div className="d-flex justify-content-center">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : (
							<>
								{data && data.map((item, key) => (
									
								<div className='row' key={key}>
									<div className="col-xxl-4  col-xxl-4 col-md-7 col-12 mb-3">
										<div className='row g-2'>
											<div className='col-4'>
												<div className="bg-gradient3 rounded text-center pt-2">
													<div className="d-inline-block position-relative donut-chart-sale">
														<DonutChart value={item.rekap.persen} backgroundColor="rgba(255, 255, 255,1)"
															backgroundColor2= "rgba(255, 255, 255, 0.2)"
														/>
														<small className="text-white">{<i className="fa fa-user fa-2x" aria-hidden="true"></i>}</small>
													</div>
													<span className="fs-14 text-white d-block">{item.rekap.persen} %</span>
													{/* <span className="fs-14 text-white d-block">{item.rekap.vote} / {item.rekap.total}</span> */}
												</div>

											</div>
											<div className='col-8'>
												<div className='flex'>
													<div className="list-group">
														<h5 className="list-group-item list-group-item-action active py-2 px-3">
															{item.nama_kegiatan}
														</h5>
														<div className="list-group-item p-2">
															<p>silahkan tentukan pilihan Anda pada tombol vote</p>
															{/* <p className="">Waktu Awal: <br /><strong>{new Date(item.tanggal_awal).toLocaleDateString('ID')}</strong></p>
															<p className="">Waktu Berakhir: <br /><strong>{new Date(item.tanggal_akhir).toLocaleDateString('ID')}</strong></p> */}
															<div className="d-flex justify-content-end p-2">
																<Link to={`/vote/${item.kegiatan_id}`} className="btn btn-primary"><i className="fas fa-bullhorn"></i> Vote</Link>
															</div>
														</div>
													</div>
													{/* <h4>{item.nama_kegiatan}</h4> */}
												</div>
											</div>
										</div>

									</div>								
								</div>
								))}

							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
export default Home;