import Swal from "sweetalert2";
import { UseAPI } from "./UseAPI";

export async function SwalDelete(id,data) {

    return Swal.fire({
        title: 'Anda yakin?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Deleting...',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          try {
            await UseAPI(data.url,{}, 'DELETE');
            Swal.close();
          } finally {
            Swal.close();
            return true;
          }
        }
      });    
}