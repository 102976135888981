import React, { useEffect, useState } from 'react';
import {useRouteMatch} from 'react-router-dom';
import { UseAPI } from '../../services/UseAPI';
import Swal from 'sweetalert2';

const Home = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedCount, setSelectedCount] = useState(0);
	const [formData, setFormData] = useState([]);
    const router = useRouteMatch();
	useEffect(() => {
		UseAPI(`vote/${router.params.id}`, {}, 'GET')
		.then((resp) => {
				setData(resp.data);
				setLoading(false);
			})
	}, []);

    const simpan_vote = async (id) => {
        Swal.fire({
            title: 'Anda yakin?',
            html: `<p>Kandidat yang anda vote :</p><p>${formData.map(item => item.nama).join('<br />')}</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Batal',
            confirmButtonText: 'Kirim'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Memproses...',
                    html: 'Mohon tunggu sebentar.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                const response = await UseAPI(`vote/${id}`, {data: JSON.stringify(formData.map(item => ({kegiatan_id: item.kegiatan_id, kandidat_id: item.kandidat_id})))}, 'POST');
                Swal.close();
                if (response) {
                    Swal.fire({
                        title: response.status === 'er' ?'Gagal':'Sukses',
                        html: `<p>${response.pesan}</p>`,
                        icon: `${response.status === 'er' ? 'error':'success'}`,
                        confirmButtonText: 'OK'
                    });
                }
            }
        });
    // const axios = require('axios');

    // axios.post(`https://apps.unismuh.ac.id/vote_api/vote/${id}`, {
    //     data: JSON.stringify(formData.map(item => ({
    //         kegiatan_id: item.kegiatan_id,
    //         kandidat_id: item.kandidat_id
    //     })))
    // })
    // .then((response) => {
    //     console.log(response.data);
    //     Swal.fire({
    //         title: 'Success',
    //         text: 'Vote has been saved successfully',
    //         icon: 'success',
    //         confirmButtonText: 'OK'
    //     });
    // })
    // .catch((error) => {
    //     console.error('There was an error saving the vote!', error);
    //     Swal.fire({
    //         title: 'Error',
    //         text: 'There was an error saving your vote. Please try again.',
    //         icon: 'error',
    //         confirmButtonText: 'OK'
    //     });
    // });
    }

	const handleCheckboxChange = (event, item) => {
        console.log(item);
		if (event.target.checked) {
			if (selectedCount < item.jumlah_dipilih) {
				setSelectedCount(prevCount => prevCount + 1);
				formData.push({kegiatan_id: item.kegiatan_id, kandidat_id: item.peserta_id, nama: item.nama_peserta});
				setFormData(formData);
			} else {
				event.preventDefault();
				event.target.checked = false;
				Swal.fire({
					title: 'Maaf',
					text: 'Kandidat yg harus dipilih masksimal '+item.jumlah_dipilih,
					icon: 'warning',
					confirmButtonText: 'OK'
				});
			}
		} else {
			setSelectedCount(prevCount => prevCount - 1);
			const index = formData.findIndex(itemData => itemData.kegiatan_id === item.kegiatan_id);
			if (index > -1) {
				formData.splice(index, 1);
			}
			setFormData(formData);
		}
	};

	return (
		<>
			<div>
				<div className="d-flex justify-content-between" style={{ position: 'relative' }}>
					<button onClick={() => window.history.back()} className="btn btn-secondary mb-3">
						<i className="fas fa-arrow-left"></i> Kembali
					</button>
					<button onClick={() => simpan_vote(router.params.id)} className="btn btn-secondary mb-3">
						<i className="fas fa-save"></i> Simpan Pilihan
					</button>
				</div>

				{loading ? (
					<div className="d-flex justify-content-center">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				) : (
						<form style={{marginBottom:'90px'}}>
							{data && data.map((item, key) => (
								<div key={key} className="card" style={{ margin: '0 auto', marginTop: '10px', borderRadius: '8px' }}>
									<div className="card-body">
										<div className='row'>
											<div className="col-6">
												<h5 className="py-2 px-3">
													{item.nama_peserta}
												</h5>
											</div>
											<div className="col-6 d-flex justify-content-end align-items-center">
												<div className="form-check">
													<input name={`voteCheckbox${item.kegiatan_id}`} className="form-check-input border border-3 border-black" type="checkbox" onChange={(event) => handleCheckboxChange(event, item)} id={`voteCheckbox${item.kegiatan_id}`} style={{ transform: 'scale(1.5)', marginRight: '10px' }} disabled={selectedCount >= 3 && !document.getElementById(`voteCheckbox${item.kegiatan_id}`).checked} />
													<label className="form-check-label" htmlFor={`voteCheckbox${item.kegiatan_id}`} style={{ fontWeight: 'bold' }}>
														Pilih
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}							
						</form>
				)}
			</div>
		</>
	);
}
export default Home;
