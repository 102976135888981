import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, Modal, Button, Form } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";


import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { UseAPI } from "../../../services/UseAPI";

const Header = ({ onNote }) => {
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("_");
  var filterName = path.length >= 3 ? path.filter((n, i) => i > 1) : name;
  var finalName = filterName;

  const navigate = useHistory();
  if (Number.isInteger(parseInt(finalName[finalName.length-1]))) {
    //console.log('nomor');
    finalName = finalName[0];
  } else {
    //console.log('huruf');

  }
  const signOut = () => {
    Swal.fire({
      title: 'Anda yakin?',
      text: "Anda akan keluar dari aplikasi !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, keluar!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.href="/"
      }
    })
  }

  const gantiPassword = () => {
    setShowModal(true);
  }

  const handlePasswordChange = async () => {
    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match!',
      });
      return;
    }
    Swal.showLoading();
    const response = await UseAPI(`peserta/ganti_password`, { password: password }, 'POST')
    Swal.close();
    setShowModal(false);
    Swal.fire({
      icon: response.status,
      title: response.status,
      text: response.pesan,
    });

    // Handle password change logic here
  }

  const adminPage = () => {
    navigate.push('/admin');
  }

  //console.log(finalName);
  return ( 
    <div className="header border-bottom">
      <div className="header-content m-0 p-0">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="navbar-nav">
              <div className="dashboard_bar" style={{ textTransform: "capitalize",fontSize:'1.5rem' }}>
                e-Vote App
              </div>
            </div>
            <ul className="navbar-nav px-3">
              <li className="nav-item">                
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    className="btn btn-sm btn-primary mt-1 mb-2"
                    data-bs-toggle="dropdown"
                    drop="left"
                  >
                    Menu
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="admin">Admin</Dropdown.Item> */}
                    {/* <Dropdown.Item href="#"><NavLink >Admin</NavLink></Dropdown.Item> */}
                    <Dropdown.Item href="#" onClick={()=>adminPage()}>Admin</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={()=>gantiPassword()}>Ganti Password</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={()=>signOut()}>Keluar</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>      
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ganti Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPassword">
              <Form.Label>Password Baru</Form.Label>
              <Form.Control
                type="password"
                placeholder="Masukkan password baru"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Konfirmasi Password</Form.Label>
              <Form.Control 
                type="password"
                placeholder="Konfirmasi password baru"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Batal
          </Button>
          <Button variant="primary" onClick={handlePasswordChange}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
