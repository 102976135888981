import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import { UseAPI } from "../../../services/UseAPI";
import { SwalDelete } from "../../../services/SwalAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const KegiatanPeserta = () => {
    const history = useHistory();
    const [currentData, setCurrentData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const router = useRouteMatch();

    React.useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const response = await UseAPI(`admin/peserta/${router.params.id}`, {}, 'GET');
        setCurrentData(response);
        setLoading(false);
      };
      fetchData();
    }, [router.params.id]);

    const deleteData = async (id) => {
      const data = {
        url: `admin/peserta/${id}`
      }
      const result = await SwalDelete(id, data);
      setCurrentData(currentData.filter(item => item.peserta_kegiatan_id !== id));
    };

    return (
      <>
        <div>
          <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
            <button onClick={() => history.push('/admin')} className="btn btn-secondary mb-3">
              <i className="fas fa-arrow-left"></i> Kembali
            </button>
            <button onClick={() => history.push(`/admin/kegiatan/peserta/tambah/${router.params.id}`)} className="btn btn-secondary mb-3">
              <FontAwesomeIcon icon={faPlus} /> Peserta
            </button>
          </div>
          <h5>Daftar Peserta</h5>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="card mt-4">
            <div className="card-header">
              <div className="flex flex-justify-between">
                Nama Peserta
              </div>
              <div className="flex flex-justify-between">
                Aksi
              </div>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {currentData.map((data, index) => (
                  <li key={index} className="d-flex justify-content-between align-items-center py-2">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {data.nama_peserta}
                      </div>
                    </div>
                    <div>
                      <button className="btn btn-primary btn-sm" onClick={() => deleteData(data.peserta_kegiatan_id)}>
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </>
    );
}
export default KegiatanPeserta;
