import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Dropdown } from "react-bootstrap";
import { UseAPI } from "../../../services/UseAPI";
import Swal from "sweetalert2";

const KegiatanPesertaTambah = () => {
    const history = useHistory();
    const [currentData, setCurrentData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState([]);
    const router = useRouteMatch();
    React.useEffect( async() => {
      const response =  await UseAPI(`admin/peserta/tambah/${router.params.id}`,{},'GET');
      setCurrentData(response);
      setLoading(false)
    }, []);

    const handleCheckboxChange = (data) => {
      setSelectedData(prevSelectedData => {
        if (prevSelectedData.includes(data)) {
          return prevSelectedData.filter(item => item.id !== data.id);
        } else {
          return [...prevSelectedData, data.id];
        }
      });
    };

    const handleSubmit = async () => {
      Swal.fire({
        title: 'Processing...',
        text: 'Please wait',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const formData = {
        "data": JSON.stringify(selectedData)
      }
      const result = await UseAPI(`admin/peserta/tambah/${router.params.id}`, formData, 'POST');
      console.log("result",result);
      Swal.fire(
        {        
          title : result.status,
          text:result.message,
          icon: result.status
        }
      )
      // Swal.close();
    };
  
  return (

    <>
      <div>
        <div className="d-flex justify-content-between" style={{ position: 'relative' }}>
          <button onClick={()=>history.push(`/admin/kegiatan/peserta/${router.params.id}`)} className="btn btn-secondary mb-3">
            <i className="fas fa-arrow-left"></i> Kembali
          </button>
          <button onClick={handleSubmit} className="btn btn-primary mb-3">
            Submit
          </button>
        </div>
        <h5>Daftar Peserta</h5>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="card mt-4">
          <div className="card-header">
            <div className="flex flex-justify-between">
              Nama Peserta
            </div>
            <div className="flex flex-justify-between">
              Aksi
            </div>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              {currentData.map((data, index) => (
                <li key={index} className="d-flex justify-content-between align-items-center">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="checkbox" style={{marginRight:'10px'}} onChange={() => handleCheckboxChange(data)} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      {data.nama_peserta}<br />
                      {data.kode_peserta}
                    </div>
                  </div>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id={`dropdown-basic-${index}`}>
                        Actions
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => history.push(`/admin/kegiatan/edit/${data.id_kegiatan}`)}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => history.push(`/admin/kegiatan/delete/${data.id_kegiatan}`)}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>
              ))}
            </ul>            
          </div>
        </div>
      )}
    </>


  )
}
export default KegiatanPesertaTambah;